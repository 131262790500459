var exports = {};
exports = {
  ".rounded-box": {
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-t-box": {
    "borderTopLeftRadius": "var(--rounded-box, 1rem)",
    "borderTopRightRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-b-box": {
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)",
    "borderBottomRightRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-l-box": {
    "borderTopLeftRadius": "var(--rounded-box, 1rem)",
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-r-box": {
    "borderTopRightRadius": "var(--rounded-box, 1rem)",
    "borderBottomRightRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-br-box": {
    "borderBottomRightRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-tr-box": {
    "borderTopRightRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-bl-box": {
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-tl-box": {
    "borderTopLeftRadius": "var(--rounded-box, 1rem)"
  },
  ".rounded-btn": {
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".rounded-badge": {
    "borderRadius": "var(--rounded-badge, 1.9rem)"
  },
  ".text-2xs": {
    "fontSize": "0.7rem"
  },
  ".glass,\n  .glass:hover,\n  .glass.btn-active": {
    "border": "none",
    "WebkitBackdropFilter": "blur(var(--glass-blur, 40px))",
    "backdropFilter": "blur(var(--glass-blur, 40px))",
    "backgroundColor": "transparent",
    "backgroundImage": "linear-gradient(\n        135deg,\n        rgb(255 255 255 / var(--glass-opacity, 30%)) 0%,\n        rgb(0 0 0 / 0%) 100%\n      ),\n      linear-gradient(\n        var(--glass-reflex-degree, 100deg),\n        rgb(255 255 255 / var(--glass-reflex-opacity, 10%)) 25%,\n        rgb(0 0 0 / 0%) 25%\n      )",
    "boxShadow": "0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%))\n        inset,\n      0 0 0 2px rgb(0 0 0 / 5%)",
    "textShadow": "0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%))"
  },
  ".min-h-6": {
    "minHeight": "1.5rem"
  },
  ".min-h-8": {
    "minHeight": "2rem"
  },
  ".min-h-12": {
    "minHeight": "3rem"
  },
  ".min-h-16": {
    "minHeight": "4rem"
  },
  ".no-animation": {
    "-BtnFocusScale": "1",
    "-AnimationBtn": "0",
    "-AnimationInput": "0"
  },
  ".tab-border-none": {
    "-TabBorder": "0px"
  },
  ".tab-border": {
    "-TabBorder": "1px"
  },
  ".tab-border-2": {
    "-TabBorder": "2px"
  },
  ".tab-border-3": {
    "-TabBorder": "3px"
  },
  ".tab-rounded-none": {
    "-TabRadius": "0"
  },
  ".tab-rounded-lg": {
    "-TabRadius": "0.5rem"
  }
};
export default exports;