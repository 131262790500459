import * as _postcssJs2 from "postcss-js";

var _postcssJs = "default" in _postcssJs2 ? _postcssJs2.default : _postcssJs2;

import _postcssPrefixer from "./lib/postcss-prefixer";
import _package from "../package.json";
import _index from "./colors/index";
import _utilities from "../dist/utilities";
import _base from "../dist/base";
import _unstyled from "../dist/unstyled";
import _unstyled2 from "../dist/unstyled.rtl";
import _styled from "../dist/styled";
import _styled2 from "../dist/styled.rtl";
import _utilitiesUnstyled from "../dist/utilities-unstyled";
import _utilitiesStyled from "../dist/utilities-styled";
import _themes from "./colors/themes";
import _functions from "./colors/functions";
import * as _plugin2 from "tailwindcss/plugin";

var _plugin = "default" in _plugin2 ? _plugin2.default : _plugin2;

var exports = {};
const postcssJs = _postcssJs;
const postcssPrefix = _postcssPrefixer;
const daisyuiInfo = _package;
const colors = _index;
const utilities = _utilities;
const base = _base;
const unstyled = _unstyled;
const unstyledRtl = _unstyled2;
const styled = _styled;
const styledRtl = _styled2;
const utilitiesUnstyled = _utilitiesUnstyled;
const utilitiesStyled = _utilitiesStyled;
const themes = _themes;
const colorFunctions = _functions;

const mainFunction = ({
  addBase,
  addComponents,
  addUtilities,
  config,
  postcss
}) => {
  let diasyuiIncludedItems = [];
  let logs = false;

  if (config("daisyui.logs") != false) {
    logs = true;
  }

  if (logs) {
    console.log();
    console.log("\x1B[35m%s\x1B[0m", "\uD83C\uDF3C daisyUI components " + daisyuiInfo.version, "\x1B[0m", daisyuiInfo.homepage);
    console.group();
  } // inject @base style


  if (config("daisyui.base") != false) {
    addBase(base);
    diasyuiIncludedItems.push("base");
  } // inject components
  // because rollupjs doesn't supprt dynamic require


  let file = styled;

  if (config("daisyui.styled") == false && config("daisyui.rtl") != true) {
    diasyuiIncludedItems.push("unstyled components");
    file = unstyled;
  } else if (config("daisyui.styled") == false && config("daisyui.rtl") == true) {
    diasyuiIncludedItems.push("unstyled components");
    console.log("\x1B[36m%s\x1B[0m", " Direction:", "\x1B[0m", "RTL");
    file = unstyledRtl;
  } else if (config("daisyui.styled") != false && config("daisyui.rtl") != true) {
    diasyuiIncludedItems.push("components");
    file = styled;
  } else if (config("daisyui.styled") !== false && config("daisyui.rtl") == true) {
    diasyuiIncludedItems.push("components");
    console.log("\x1B[36m%s\x1B[0m", " Direction:", "\x1B[0m", "RTL");
    file = styledRtl;
  } // add prefix to class names if specified


  const prefix = config("daisyui.prefix");
  let postcssJsProcess;

  if (prefix) {
    try {
      postcssJsProcess = postcssJs.sync(postcssPrefix({
        prefix,
        ignore: []
      }));
    } catch (error) {
      logs && console.error(`Error occurred and prevent applying the "prefix" option:`, error);
    }
  }

  const shouldApplyPrefix = prefix && postcssJsProcess;

  if (shouldApplyPrefix) {
    file = postcssJsProcess(file);
  }

  addComponents(file);
  const themeInjector = colorFunctions.injectThemes(addBase, config, themes);
  themeInjector;
  diasyuiIncludedItems.push("themes[" + themeInjector.themeOrder.length + "]"); // inject @utilities style needed by components

  if (config("daisyui.utils") != false) {
    addComponents(utilities, {
      variants: ["responsive"]
    });
    let toAdd = utilitiesUnstyled; // shadow clone here to avoid mutate the original

    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }

    addComponents(toAdd, {
      variants: ["responsive"]
    });
    toAdd = utilitiesStyled;

    if (shouldApplyPrefix) {
      toAdd = postcssJsProcess(toAdd);
    }

    addComponents(toAdd, {
      variants: ["responsive"]
    });
    diasyuiIncludedItems.push("utilities");
  }

  if (logs) {
    console.log("\x1B[32m%s\x1B[0m", "\u2714\uFE0E Including:", "\x1B[0m", "" + diasyuiIncludedItems.join(", "));
    console.log();
    console.groupEnd();
  }
};

exports = _plugin(mainFunction, {
  theme: {
    extend: {
      colors
    }
  }
});
export default exports;