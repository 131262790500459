var exports = {};
exports = {
  ".artboard-demo": {
    "display": "flex",
    "flex": "none",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center"
  },
  ".artboard.phone": {
    "width": "320px"
  },
  ".artboard.phone-1": {
    "width": "320px",
    "height": "568px"
  },
  ".artboard.phone-1.horizontal,\n      .artboard.phone-1.artboard-horizontal": {
    "width": "568px",
    "height": "320px"
  },
  ".artboard.phone-2": {
    "width": "375px",
    "height": "667px"
  },
  ".artboard.phone-2.horizontal,\n      .artboard.phone-2.artboard-horizontal": {
    "width": "667px",
    "height": "375px"
  },
  ".artboard.phone-3": {
    "width": "414px",
    "height": "736px"
  },
  ".artboard.phone-3.horizontal,\n      .artboard.phone-3.artboard-horizontal": {
    "width": "736px",
    "height": "414px"
  },
  ".artboard.phone-4": {
    "width": "375px",
    "height": "812px"
  },
  ".artboard.phone-4.horizontal,\n      .artboard.phone-4.artboard-horizontal": {
    "width": "812px",
    "height": "375px"
  },
  ".artboard.phone-5": {
    "width": "414px",
    "height": "896px"
  },
  ".artboard.phone-5.horizontal,\n      .artboard.phone-5.artboard-horizontal": {
    "width": "896px",
    "height": "414px"
  },
  ".artboard.phone-6": {
    "width": "320px",
    "height": "1024px"
  },
  ".artboard.phone-6.horizontal,\n      .artboard.phone-6.artboard-horizontal": {
    "width": "1024px",
    "height": "320px"
  },
  ".badge-xs": {
    "height": "0.75rem",
    "fontSize": "0.75rem",
    "lineHeight": ".75rem",
    "paddingLeft": "0.313rem",
    "paddingRight": "0.313rem"
  },
  ".badge-sm": {
    "height": "1rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "paddingLeft": "0.438rem",
    "paddingRight": "0.438rem"
  },
  ".badge-md": {
    "height": "1.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "paddingLeft": "0.563rem",
    "paddingRight": "0.563rem"
  },
  ".badge-lg": {
    "height": "1.5rem",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "paddingLeft": "0.688rem",
    "paddingRight": "0.688rem"
  },
  ".btm-nav-xs": {
    "height": "2.5rem"
  },
  ".btm-nav-xs>*:where(.active)": {
    "borderTopWidth": "1px"
  },
  ".btm-nav-xs .btm-nav-label": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".btm-nav-sm": {
    "height": "3rem"
  },
  ".btm-nav-sm>*:where(.active)": {
    "borderTopWidth": "2px"
  },
  ".btm-nav-sm .btm-nav-label": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".btm-nav-md": {
    "height": "4rem"
  },
  ".btm-nav-md>*:where(.active)": {
    "borderTopWidth": "2px"
  },
  ".btm-nav-md .btm-nav-label": {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".btm-nav-lg": {
    "height": "5rem"
  },
  ".btm-nav-lg>*:where(.active)": {
    "borderTopWidth": "4px"
  },
  ".btm-nav-lg .btm-nav-label": {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  ".btn-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "minHeight": "1.5rem",
    "fontSize": "0.75rem"
  },
  ".btn-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "minHeight": "2rem",
    "fontSize": "0.875rem"
  },
  ".btn-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "minHeight": "3rem",
    "fontSize": "0.875rem"
  },
  ".btn-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "minHeight": "4rem",
    "fontSize": "1.125rem"
  },
  ".btn-wide": {
    "width": "16rem"
  },
  ".btn-block": {
    "width": "100%"
  },
  ".btn-square:where(.btn-xs)": {
    "height": "1.5rem",
    "width": "1.5rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-sm)": {
    "height": "2rem",
    "width": "2rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-md)": {
    "height": "3rem",
    "width": "3rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-lg)": {
    "height": "4rem",
    "width": "4rem",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-xs)": {
    "height": "1.5rem",
    "width": "1.5rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-sm)": {
    "height": "2rem",
    "width": "2rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-md)": {
    "height": "3rem",
    "width": "3rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-lg)": {
    "height": "4rem",
    "width": "4rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".card-side": {
    "alignItems": "stretch",
    "flexDirection": "row"
  },
  ".card-side figure > *": {
    "maxWidth": "unset"
  },
  ":where(.card-side figure > *)": {
    "width": "100%",
    "height": "100%",
    "objectFit": "cover"
  },
  ".checkbox-xs": {
    "height": "1rem",
    "width": "1rem"
  },
  ".checkbox-sm": {
    "height": "1.25rem",
    "width": "1.25rem"
  },
  ".checkbox-md": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".checkbox-lg": {
    "height": "2rem",
    "width": "2rem"
  },
  ".divider-horizontal": {
    "flexDirection": "column"
  },
  ".divider-horizontal:before": {
    "height": "100%",
    "width": "0.125rem"
  },
  ".divider-horizontal:after": {
    "height": "100%",
    "width": "0.125rem"
  },
  ".divider-vertical": {
    "flexDirection": "row"
  },
  ".divider-vertical:before": {
    "height": "0.125rem",
    "width": "100%"
  },
  ".divider-vertical:after": {
    "height": "0.125rem",
    "width": "100%"
  },
  ".indicator :where(.indicator-item)": {
    "right": "0px",
    "left": "auto",
    "top": "0px",
    "bottom": "auto",
    "-TwTranslateX": "50%",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-start)": {
    "right": "auto",
    "left": "0px",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-center)": {
    "right": "50%",
    "left": "50%",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-end)": {
    "right": "0px",
    "left": "auto",
    "-TwTranslateX": "50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-bottom)": {
    "top": "auto",
    "bottom": "0px",
    "-TwTranslateY": "50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-middle)": {
    "top": "50%",
    "bottom": "50%",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-top)": {
    "top": "0px",
    "bottom": "auto",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".input-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".input-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"]
  },
  ".input-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": "2rem"
  },
  ".input-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"]
  },
  ".kbd-xs": {
    "paddingLeft": "0.25rem",
    "paddingRight": "0.25rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "minHeight": "1.2em",
    "minWidth": "1.2em"
  },
  ".kbd-sm": {
    "paddingLeft": "0.25rem",
    "paddingRight": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "minHeight": "1.6em",
    "minWidth": "1.6em"
  },
  ".kbd-md": {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "minHeight": "2.2em",
    "minWidth": "2.2em"
  },
  ".kbd-lg": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem",
    "minHeight": "2.5em",
    "minWidth": "2.5em"
  },
  ".menu-vertical": {
    "flexDirection": "column"
  },
  ".menu-vertical :where(li)": {
    "flexDirection": "column"
  },
  ".menu-vertical > :where(li) > :where(ul)": {
    "top": "initial",
    "left": "100%"
  },
  ".menu-horizontal": {
    "display": "inline-flex",
    "flexDirection": "row"
  },
  ".menu-horizontal :where(li)": {
    "flexDirection": "row"
  },
  ".menu-horizontal > :where(li) > :where(ul)": {
    "top": "100%",
    "left": "initial"
  },
  ".modal-bottom": {
    "alignItems": "flex-end"
  },
  ".modal-middle": {
    "alignItems": "center"
  },
  ".radio-xs": {
    "height": "1rem",
    "width": "1rem"
  },
  ".radio-sm": {
    "height": "1.25rem",
    "width": "1.25rem"
  },
  ".radio-md": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".radio-lg": {
    "height": "2rem",
    "width": "2rem"
  },
  ".range-xs": {
    "height": "1rem"
  },
  ".range-xs::-webkit-slider-runnable-track": {
    "height": "0.25rem"
  },
  ".range-xs::-moz-range-track": {
    "height": "0.25rem"
  },
  ".range-xs::-webkit-slider-thumb": {
    "height": "1rem",
    "width": "1rem",
    "-FillerOffset": "0.4rem"
  },
  ".range-xs::-moz-range-thumb": {
    "height": "1rem",
    "width": "1rem",
    "-FillerOffset": "0.4rem"
  },
  ".range-sm": {
    "height": "1.25rem"
  },
  ".range-sm::-webkit-slider-runnable-track": {
    "height": "0.25rem"
  },
  ".range-sm::-moz-range-track": {
    "height": "0.25rem"
  },
  ".range-sm::-webkit-slider-thumb": {
    "height": "1.25rem",
    "width": "1.25rem",
    "-FillerOffset": "0.5rem"
  },
  ".range-sm::-moz-range-thumb": {
    "height": "1.25rem",
    "width": "1.25rem",
    "-FillerOffset": "0.5rem"
  },
  ".range-md": {
    "height": "1.5rem"
  },
  ".range-md::-webkit-slider-runnable-track": {
    "height": "0.5rem"
  },
  ".range-md::-moz-range-track": {
    "height": "0.5rem"
  },
  ".range-md::-webkit-slider-thumb": {
    "height": "1.5rem",
    "width": "1.5rem",
    "-FillerOffset": "0.6rem"
  },
  ".range-md::-moz-range-thumb": {
    "height": "1.5rem",
    "width": "1.5rem",
    "-FillerOffset": "0.6rem"
  },
  ".range-lg": {
    "height": "2rem"
  },
  ".range-lg::-webkit-slider-runnable-track": {
    "height": "1rem"
  },
  ".range-lg::-moz-range-track": {
    "height": "1rem"
  },
  ".range-lg::-webkit-slider-thumb": {
    "height": "2rem",
    "width": "2rem",
    "-FillerOffset": "1rem"
  },
  ".range-lg::-moz-range-thumb": {
    "height": "2rem",
    "width": "2rem",
    "-FillerOffset": "1rem"
  },
  ".rating-xs input": {
    "height": "0.75rem",
    "width": "0.75rem"
  },
  ".rating-sm input": {
    "height": "1rem",
    "width": "1rem"
  },
  ".rating-md input": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".rating-lg input": {
    "height": "2.5rem",
    "width": "2.5rem"
  },
  ".rating-half.rating-xs input:not(.rating-hidden)": {
    "width": "0.375rem"
  },
  ".rating-half.rating-sm input:not(.rating-hidden)": {
    "width": "0.5rem"
  },
  ".rating-half.rating-md input:not(.rating-hidden)": {
    "width": "0.75rem"
  },
  ".rating-half.rating-lg input:not(.rating-hidden)": {
    "width": "1.25rem"
  },
  ".select-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "2.5rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "minHeight": "3rem"
  },
  ".select-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "2rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"],
    "minHeight": "4rem"
  },
  ".select-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "2rem",
    "fontSize": "0.875rem",
    "lineHeight": "2rem",
    "minHeight": "2rem"
  },
  ".select-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "2rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"],
    "minHeight": "1.5rem"
  },
  ".stats-horizontal": {
    "gridAutoFlow": "column"
  },
  ".stats-vertical": {
    "gridAutoFlow": "row"
  },
  ".steps-horizontal": {
    "gridAutoColumns": "1fr",
    "display": "inline-grid",
    "gridAutoFlow": "column",
    "overflow": "hidden",
    "overflowX": "auto"
  },
  ".steps-horizontal .step": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gridTemplateRows": "repeat(2, minmax(0, 1fr))",
    "placeItems": "center",
    "textAlign": "center"
  },
  ".steps-vertical": {
    "gridAutoRows": "1fr",
    "gridAutoFlow": "row"
  },
  ".steps-vertical .step": {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
    "gridTemplateRows": "repeat(1, minmax(0, 1fr))"
  },
  ".tab-md": {
    "height": "2rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "-TabPadding": "1rem"
  },
  ".tab-lg": {
    "height": "3rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"],
    "-TabPadding": "1.25rem"
  },
  ".tab-sm": {
    "height": "1.5rem",
    "fontSize": "0.875rem",
    "lineHeight": ".75rem",
    "-TabPadding": "0.75rem"
  },
  ".tab-xs": {
    "height": "1.25rem",
    "fontSize": "0.75rem",
    "lineHeight": ".75rem",
    "-TabPadding": "0.5rem"
  },
  ":where(.toast)": {
    "right": "0px",
    "left": "auto",
    "top": "auto",
    "bottom": "0px",
    "-TwTranslateX": "0px",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-start)": {
    "right": "auto",
    "left": "0px",
    "-TwTranslateX": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-center)": {
    "right": "50%",
    "left": "50%",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-end)": {
    "right": "0px",
    "left": "auto",
    "-TwTranslateX": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-bottom)": {
    "top": "auto",
    "bottom": "0px",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-middle)": {
    "top": "50%",
    "bottom": "auto",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-top)": {
    "top": "0px",
    "bottom": "auto",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toggle-xs": {
    "-Handleoffset": "0.5rem",
    "height": "1rem",
    "width": "1.5rem"
  },
  ".toggle-sm": {
    "-Handleoffset": "0.75rem",
    "height": "1.25rem",
    "width": "2rem"
  },
  ".toggle-md": {
    "-Handleoffset": "1.5rem",
    "height": "1.5rem",
    "width": "3rem"
  },
  ".toggle-lg": {
    "-Handleoffset": "2rem",
    "height": "2rem",
    "width": "4rem"
  }
};
export default exports;